.leftToolbarComponents {
  float: left;
}

.rightToolbarComponents {
  float: right;
}

.logo {
  margin-right: 20px;
  width: 60px;
  max-width: 100%;
  height: auto;
  filter: brightness(0) invert(1);
  object-fit: cover;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
  margin-left: 20px;
  font-size: 20px;
}

@media screen and (max-width: 400px) {
  .rightToolbarComponents {
    float: right;
  }

  .logo {
    margin-right: 20px;
    width: 60px;
    max-width: 100%;
    height: auto;
    filter: brightness(0) invert(1);
    object-fit: cover;
  }
}
