body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "custom-font";
  /* src: url("/assets/fonts/PoiretOne-Regular.ttf") format("truetype"); */
  src: url("./assets/fonts/OpenSansCondensed-Light.ttf") format("truetype");
}
